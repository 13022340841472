// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../src/pages/FAQ.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-find-dit-optimale-kanalmiks-til-intern-kommunikation-jsx": () => import("./../src/pages/find-dit-optimale-kanalmiks-til-intern-kommunikation.jsx" /* webpackChunkName: "component---src-pages-find-dit-optimale-kanalmiks-til-intern-kommunikation-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-introduktion-til-en-medarbejderapp-jsx": () => import("./../src/pages/introduktion-til-en-medarbejderapp.jsx" /* webpackChunkName: "component---src-pages-introduktion-til-en-medarbejderapp-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-ledige-stillinger-jsx": () => import("./../src/pages/ledige-stillinger.jsx" /* webpackChunkName: "component---src-pages-ledige-stillinger-jsx" */),
  "component---src-pages-om-os-jsx": () => import("./../src/pages/om-os.jsx" /* webpackChunkName: "component---src-pages-om-os-jsx" */),
  "component---src-pages-webinar-intern-kommunikation-jsx": () => import("./../src/pages/webinar-intern-kommunikation.jsx" /* webpackChunkName: "component---src-pages-webinar-intern-kommunikation-jsx" */),
  "component---src-pages-webinar-intern-kommunikation-tak-for-din-tilmelding-jsx": () => import("./../src/pages/webinar-intern-kommunikation-tak-for-din-tilmelding.jsx" /* webpackChunkName: "component---src-pages-webinar-intern-kommunikation-tak-for-din-tilmelding-jsx" */)
}

